import React from "react"
import moment from "moment"
import { Link, graphql } from "gatsby"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext
  let recommend

  const meta = [{
    property: `og:url`,
    content: `https://kyo.simodake.work${encodeURI(post.fields.slug)}`,
  }, {
    property: `og:type`,
    content: `blog`,
  }]
  if(post.frontmatter.eyecatch) {
    meta.push({
        property: `og:image`,
        content: `https://kyo.simodake.work${post.frontmatter.eyecatch}`,
      })
    // metadata = await sharp(".${post.frontmatter.eyecatch}").metadata()
    // meta.push({
    //   property: `og:image:width`,
    //   content: `${metadata.width}`,
    // })
    // meta.push({
    //   property: `og:image:height`,
    //   content: `${metadata.height}`,
    // })
    meta.push({
      property: `og:image:width`,
      content: `1280`,
    })
    meta.push({
      property: `og:image:height`,
      content: `720`,
    })
    meta.push({
      name: `robots`,
      content: `max-image-preview:large`,
    })
    meta.push({
      property: `twitter:card`,
      content: `summary_large_image`,
    })
  }
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        meta={meta}
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <h1
          style={{
            marginTop: rhythm(1),
            marginBottom: 0,
          }}
        >
          {post.frontmatter.title}
        </h1>
        <div style={{ "text-align": "right", margin: "10px" }}>
          {post.frontmatter.tags && (
            <nav
              class="tag"
              style={{ display: "inline-block", margin: "0 10px" }}
            >
              <ul style={{ margin: 0 }}>
                {post.frontmatter.tags.map(tag => (
                  <li style={{ margin: "0 7px" }}>
                    <a href={`/tags/${encodeURIComponent(tag)}/`}>{tag}</a>
                  </li>
                ))}
                {post.fields && post.fields.slug && (
                  <li
                    class="btn-standard lang-en"
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "rgb(255, 255, 255)",
                      "font-weight": "700",
                      "line-height": "1",
                      "align-items": "center",
                      "justify-content": "center",
                      "border-radius": "3px",
                      background: "rgb(0, 164, 222)",
                      margin: "0 7px 2px",
                    }}
                  >
                    <a
                      href={
                        "https://b.hatena.ne.jp/site/kyo.simodake.work/#bookmark_url=https%3A%2F%2Fkyo.simodake.work" +
                        post.fields.slug
                      }
                      target="_top"
                      title="このエントリーをはてなブックマークに追加"
                      style={{
                        "justify-content": "center",
                        "align-items": "center",
                        width: "100%",
                        height: "100%",
                        "text-decoration": "none",
                        color: "#fff",
                        "background-color": "inherit",
                        border: "none",
                        padding: "1px 5px"
                      }}
                    >
                      <img
                        src="/img/hatenabookmark.svg"
                        alt=""
                        style={{
                          width: "12px",
                          "margin-top": "2px",
                        }}
                      />
                    </a>
                  </li>
                )}
                {post.fields && post.fields.slug && (
                  <li
                    class="btn-standard lang-en"
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "rgb(255, 255, 255)",
                      "line-height": "1",
                      "align-items": "center",
                      "justify-content": "center",
                      "border-radius": "3px",
                      margin: "0 7px 2px",
                    }}
                  >
                    <a
                      href={
                        `https://x.com/intent/post?url=https%3A%2F%2Fkyo.simodake.work${post.fields.slug}&text=${post.frontmatter.title}`
                      }
                      rel="nofollow noopener noreferrer" 
                      target="_blank"
                      title="このエントリーをXでpost"
                      style={{
                        "justify-content": "center",
                        "align-items": "center",
                        width: "100%",
                        height: "100%",
                        "text-decoration": "none",
                        color: "#fff",
                        "background-color": "inherit",
                        border: "none",
                      }}
                    >
                      <svg viewBox="0 0 1200 1227" xmlns="http://www.w3.org/2000/svg" role="none" class="twtr-color-fill--black" style={{
                        width: "15px",
                        height: "15px"
                      }}> 
                        <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"></path> 
                      </svg>
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          )}
          <small
            style={{
              "font-size": "0.5em",
              display: "inline-block",
            }}
          >
            {post.frontmatter.date &&
              moment(post.frontmatter.date).format("YYYY/MM/DD HH:MM")}
          </small>
        </div>
        {post.frontmatter.description && (
          <section style={{ margin: "20px 0" }}>
            {post.frontmatter.description}
          </section>
        )}
        {post.frontmatter.eyecatch && (
          <section>
            <img
              alt=""
              src={post.frontmatter.eyecatch}
              style={{ width: "100%" }}
            />
          </section>
        )}
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
        <section class="recommend">
          <h4>よく読まれている記事</h4>
          {post.fields.slug !== (recommend = `/20210208_ngsl/`) && (
            <article>
              <Link to={recommend}>
                最強の英単語学習アプリtacku!
                700単語覚えるだけで英会話が上達するNGSLを簡単に学べる！
              </Link>
            </article>
          )}
          {post.fields.slug !==
            (recommend = `/how_to_win_friends_and_influence_people_0_00/`) && (
            <article>
              <Link to={recommend}>
                「人を動かす 1936年版」目次
                【デール・カーネギー】パブリックドメインの洋書を全部現代語訳する
              </Link>
            </article>
          )}
          {post.fields.slug !== (recommend = `/20200622_rongo/`) && (
            <article>
              <Link to={recommend}>学びてときに之を習う」 の意味を考える</Link>
            </article>
          )}
        </section>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date
        description
        tags
        eyecatch
      }
    }
  }
`
